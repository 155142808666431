var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flexmar" },
    [
      _c("headbox", {
        attrs: {
          title: "快递投保",
          bgColor: "#39394D",
          isborder: "true",
          righturl: "claim",
          isbackperson: true
        }
      }),
      _c(
        "van-tabs",
        {
          attrs: {
            sticky: "",
            color: "#FF2D2E",
            "title-active-color": "#39394D",
            "title-inactive-color": "#8F8F9F"
          },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "下保单", name: "a" } },
            [_c("toubao")],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "查保单", name: "b" } },
            [_c("checkClaim")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }